import { Alpine } from '../../vendor/livewire/livewire/dist/livewire.esm';

Alpine.data('webPush', () => ({
  loading: false,

  async subscribe(crewId, crewName) {
    this.loading = true;
    await this.$store.webPush.requestPermsission();
    if (!this.$store.webPush.permission) {
      this.loading = false;
      window.toast(
        'danger',
        'Je hebt het sturen van notificaties geweigerd, waardoor je niemand kan volgen. Sta notificaties toe om je favoriete teamleden te kunnen volgen.',
      );

      return;
    }

    navigator.serviceWorker.ready
      .then((registration) => {
        return registration.pushManager.subscribe({
          userVisibleOnly: true,
          applicationServerKey: import.meta.env.VITE_VAPID_PUBLIC_KEY,
        });
      })
      .then((subscription) => {
        return window.axios.post(route('api.web-push.follow'), {
          ...subscription.toJSON(),
          content_encoding: PushManager.supportedContentEncodings,
          crew_id: crewId,
        });
      })
      .then((req) => {
        this.$store.webPush.following.add(crewId);
        window.toast('success', `Geluk! Je ontvangt nu meldingen voor ${crewName} op dit apparaat.`);
      })
      .catch((e) => {
        console.error('Failed to subscribe cause of: ', e);
      })
      .finally(() => {
        this.loading = false;
      });
  },

  unsubscribe(crewId, crewName) {
    this.loading = true;
    navigator.serviceWorker.ready.then((serviceWorkerRegistration) => {
      serviceWorkerRegistration.pushManager
        .getSubscription()
        .then((subscription) => {
          return window.axios.post(route('api.web-push.unfollow'), {
            endpoint: subscription.endpoint,
            crew_id: crewId,
          });
        })
        .then((response) => {
          window.toast('success', `Geluk! Je ontvangt geen meldingen meer voor ${crewName} op dit apparaat.`);
          this.$store.webPush.following.delete(crewId);
        })
        .catch((err) => {
          console.warn(`Error during getSubscription(): ${err}`);
        })
        .finally(() => {
          this.loading = false;
        });
    });
  },
}));

Alpine.store('webPush', {
  supported: false,
  loaded: false,
  ready: false,
  permission: false,
  following: new Set(),

  init() {
    Alpine.effect(() => {
      this.ready = this.supported && this.loaded;
    });

    if ('serviceWorker' in navigator && 'PushManager' in window && 'showNotification' in ServiceWorkerRegistration.prototype) {
      this.supported = true;
      this.permission = Notification.permission === 'granted';

      if (this.permission) {
        this.getSubscriptions();
      } else {
        this.loaded = true;
      }
    }
  },

  async requestPermsission() {
    if (Notification.permission === 'granted') {
      this.permission = true;
      return;
    }

    this.permission = (await Notification.requestPermission()) === 'granted';
  },

  getSubscriptions() {
    navigator.serviceWorker.ready.then((serviceWorkerRegistration) => {
      serviceWorkerRegistration.pushManager
        .getSubscription()
        .then((subscription) => {
          if (!subscription) {
            throw new Error('No subscription found.');
          }

          return window.axios.post(route('api.web-push.follows'), { endpoint: subscription.endpoint });
        })
        .then((response) => {
          this.following = new Set(response.data);
        })
        .catch((err) => {
          console.warn(`Error during getSubscription(): ${err}`);
        })
        .finally(() => {
          this.loaded = true;
        });
    });
  },
});
