import lodash from 'lodash';
import { DateTime, Settings } from 'luxon';
import { Alpine, Livewire } from '../../vendor/livewire/livewire/dist/livewire.esm';
import axios from 'axios';
import Pusher from 'pusher-js';
import * as Popper from '@popperjs/core';
import * as bootstrap from 'bootstrap';
import Swiper from 'swiper/bundle';
import jquery from 'jquery';
import select2 from 'select2';

window._ = lodash;
window.$ = window.jQuery = jquery;
window.axios = axios;
window.axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';
window.Pusher = Pusher;
window.Popper = Popper;
window.bootstrap = bootstrap;
window.Swiper = Swiper;
select2({
  theme: 'bootstrap-5',
});

Settings.defaultLocale = 'nl';
window.DateTime = DateTime;

if ('serviceWorker' in navigator) {
  navigator.serviceWorker
    .register('/serviceworker.js', { scope: '/' })
    .then(() => {
      navigator.serviceWorker.addEventListener('message', (event) => {
        if (event.data.navigate) Livewire.navigate(event.data.navigate);
      });
    })
    .catch((error) => {
      console.error(`Service worker registration failed: ${error}`);
    });
}

Alpine.store('time', {
  init() {
    setInterval(() => {
      this.now = DateTime.now().startOf('second');
    }, 1000);
  },

  now: DateTime.now().startOf('second'),
});

window.copyToClipboard = (text) => {
  navigator.clipboard.writeText(text);
  window.toast('info', `Tekst gekopieerd naar klembord`);
};

Alpine.directive('clipboard', (el, { expression }, { cleanup }) => {
  const handler = () => {
    window.copyToClipboard(expression || el.textContent);
  };

  el.style.cursor = 'pointer';
  el.addEventListener('click', handler);
  const tooltip = new bootstrap.Tooltip(el, {
    title: 'Klik om te kopiëren',
  });

  cleanup(() => {
    delete el.style.cursor;
    el.removeEventListener('click', handler);
    tooltip.dispose();
  });
});

import './player';
import './alpine-easymde.js';
import './web-push.js';

Livewire.start();

/**
 * Echo exposes an expressive API for subscribing to channels and listening
 * for events that are broadcast by Laravel. Echo and event broadcasting
 * allow your team to quickly build robust real-time web applications.
 */

import './echo';
